import { Routes } from '@angular/router';

export const routes: Routes = [
    {
        path: '',
        data: {
            title: 'Home',
        },
        canLoad: [],
        children: [
            {
                path: '',
                loadChildren: async () => (await import('@passbot/angular/home')).HomeModule,
            },
        ],
    },
    {
        path: 'login',
        data: {
            title: 'Login / Sign-up',
        },
        children: [
            {
                path: '',
                loadChildren: async () => (await import('@passbot/angular/auth')).AuthModule,
            },
        ],
    },

    {
        path: 'signup',
        data: {
            title: 'Sign Up',
        },
        children: [
            {
                path: '',
                loadChildren: async () => (await import('@passbot/angular/signup')).SignupModule,
            },
        ],
    },

    {
        path: 'webauthn',
        data: {
            title: 'Web Authn Test',
        },
        children: [
            {
                path: '',
                loadChildren: async () => (await import('@passbot/angular/webauthn')).WebauthnModule,
            },
        ],
    },
];
