import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ToastrModule } from 'ngx-toastr';
import { register } from 'swiper/element/bundle';

import { ChargebeeModule, ChargebeeService } from '@passbot/angular/chargebee';
import { APP_CONFIG, ConfigModule, ConfigService } from '@passbot/angular/config';
import { AngularSocketIoModule } from '@passbot/angular/socket-io';

import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { routes } from './app.routes';

register();
@NgModule({ declarations: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        ConfigModule,
        RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' }),
        StoreModule.forRoot({
            router: routerReducer,
        }, {
            metaReducers: !environment.production ? [] : [],
            runtimeChecks: {
                strictActionImmutability: true,
                strictStateImmutability: true,
            },
        }),
        EffectsModule.forRoot([]),
        !environment.production ? StoreDevtoolsModule.instrument({ connectInZone: true }) : [],
        StoreRouterConnectingModule.forRoot(),
        ToastrModule.forRoot(),
        AngularSocketIoModule,
        ChargebeeModule], providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (configService: ConfigService, chargebeeService: ChargebeeService) => () => {
                configService.loadConfig(environment);
                chargebeeService.init();
            },
            deps: [ConfigService, ChargebeeService, HttpClient],
            multi: true,
        },
        { provide: 'isBrowser', useValue: true },
        {
            provide: APP_CONFIG,
            useValue: environment,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}
